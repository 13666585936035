<template>
  <div class="gestion-config-service">
    <div class="entete">
      <div class="box-setting-header">
        <div class="title loaderPosition">
          <div class="error-message ml-3">
            <div v-if="getConfErrors" class="error">
              <ul v-if="Array.isArray(getConfErrors)" class="mb-0">
                <li v-for="(e, index) in getConfErrors" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getConfErrors }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
    </div>
    <div class="chip-filters-simulation-configuration-mensuelle">
      <div v-if="getFiltre.vendeur.length" class="block-filter">
        <span class="title-block-chip">Société : </span>
        <vs-chips color="rgb(145, 32, 159)" v-model="getFiltre.vendeur">
          <vs-chip
            :key="v.text + 'proprietaire'"
            @click="removeVendeur(v)"
            v-for="v in getFiltre.vendeur"
            closable
          >
            {{ v.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="getFiltre.acheteur.length" class="block-filter">
        <span class="title-block-chip">Client : </span>
        <vs-chips color="rgb(145, 32, 159)" v-model="getFiltre.acheteur">
          <vs-chip
            :key="v.text + 'proprietaire'"
            @click="removeAcheteur(v)"
            v-for="v in getFiltre.acheteur"
            closable
          >
            {{ v.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="getFiltre.period" class="block-filter width-family-chip">
        <span class="title-block-chip">Famille : </span>
        <vs-chip
          placeholder="New Element"
          v-model="getFiltre.period"
          @click="removePeriod"
          :key="getFiltre.period.text + 'peroid'"
        >
          {{ getFiltre.period.text }}
        </vs-chip>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width: 100%">
        <b-table
          show-empty
          id="my-table"
          class="
            custom-table-style
            table-configuration-mensuel-style table-header
          "
          :items="getAllConfMensuel"
          :fields="fields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.confs.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(nom_service_bien)="data">{{
            data.item.vendeur ? data.item.vendeur : '-'
          }}</template>
          <template v-slot:cell(name)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(vendeur)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(type)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>

          <template v-slot:cell(reference)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(montant)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(tva)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(date_debut)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(date_fin)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(jour_creation)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(frequence)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(description)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template v-slot:cell(objet)="data">{{
            data.item.acheteur_id ? data.item.acheteur_id : '-'
          }}</template>
          <template #row-details="data">
            <span
              v-for="(item, index) in data.item.confs"
              :key="data.item + 'config' + index"
            >
              <template>
                <span class="align-td-item background-week">
                  <td class="item-details-style col-check-obligee">
                    <span></span>
                  </td>
                  <td class="item-details-style td-col-client">
                    {{ data.item.vendeur }}
                  </td>
                  <td class="item-details-style">
                    {{ item.vendeur }}
                  </td>
                  <td class="item-details-style">
                    {{ item.acheteur }}
                  </td>

                  <td class="item-details-style">
                    {{ item.reference }}
                  </td>
                  <td class="item-details-style">{{ item.montant }} €</td>
                  <td class="item-details-style">{{ item.tva }} %</td>
                  <td class="item-details-style">
                    {{ item.date_debut | formateDate }}
                  </td>
                  <td class="item-details-style">
                    {{ item.date_fin | formateDate }}
                  </td>
                  <td class="item-details-style">
                    {{ item.jour_creation | formateJourCreation }}
                  </td>
                  <td class="item-details-style">
                    {{ item.frequence }}
                  </td>
                  <td class="item-details-style">
                    {{ item.description ? item.description : '-' }}
                  </td>
                  <td class="item-details-style">
                    {{ item.objet ? item.objet : '-' }}
                  </td>
                  <td class="item-details-style td-col-actions">
                    <b-button
                      v-if="checkPermission('GCMSCM')"
                      class="button-warning-style mr-2"
                      size="sm"
                      variant="danger"
                      @click.prevent.stop="deleteCurrentConf(item)"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                    <b-button
                      v-if="checkPermission('GCMMCM')"
                      class="button-update-style"
                      size="sm"
                      variant="success"
                      @click.prevent.stop="updateCurrentConf(item)"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </b-button>
                    <b-button
                      v-if="checkPermission('GCMDCM')"
                      class="button-duplicate-style ml-2"
                      size="sm"
                      variant="success"
                      @click.prevent.stop="duplicateConf(item)"
                    >
                      <font-awesome-icon icon="copy" />
                    </b-button>
                  </td>
                </span>
              </template>
            </span>
          </template>
        </b-table>

        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowConfComptaRh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="updateConfComptaRh"
      ref="updateConfComptaRh"
      title="Modifier configuration"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-modal-size"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier configuration</div>
        <div class="iconClose" @click.prevent="hideModal('updateConfComptaRh')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateConfigComptaRh"
            class="form-modal-custom-style"
          >
            <div class="ModelCol">
              <b-form-group
                label="Type"
                label-for="Nom de produit"
                class="input-modal-champ col-4 p-0"
              >
                <b-form-select
                  :disabled="true"
                  id="vendeur"
                  v-model="configurationToUpdate.type"
                  required
                  :options="ListType"
                  text-field="text"
                  @change="handleErrorUpdateConfig"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Liste des configurations"
                label-for="Nom de produit"
                class="input-modal-champ col-4 p-0"
              >
                <b-form-select
                  :disabled="true"
                  id="client"
                  v-model="configurationToUpdate.service_bien"
                  required
                  :options="computedListConfig"
                  @change="handleErrorUpdateConfig"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Jour de création"
                label-for="Nom de produit"
                class="input-modal-champ col-4 p-0"
              >
                <b-form-select
                  id="client"
                  v-model="configurationToUpdate.jour_creation"
                  :options="computedListJourCreation"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Société"
                label-for="Nom de produit"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-select
                  id="vendeur"
                  v-model="configurationToUpdate.vendeur_id"
                  @change="handleErrorUpdateConfig"
                  required
                  :options="getSettingFilialesTh"
                  text-field="name"
                  value-field="id"
                  class="b-form-select-raduis"
                  :disabled="true"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Client"
                label-for="Nom de produit"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-select
                  id="client"
                  v-model="configurationToUpdate.acheteur_id"
                  required
                  :options="getSettingFilialesTh"
                  @change="handleErrorUpdateConfig"
                  text-field="name"
                  value-field="id"
                  class="b-form-select-raduis"
                  :disabled="true"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Montant Hors Taxes"
                label-for="PU HT"
                class="input-modal-champ col-2 p-0"
              >
                <b-form-input
                  id="montant"
                  @input="calculTTC"
                  v-model="configurationToUpdate.montant"
                  required
                  type="number"
                  min="0"
                  step="0.00001"
                ></b-form-input>
              </b-form-group>

              <div role="group" class="form-group col-2 input-modal-champ">
                <label for="swift-template-input" class="d-block"
                  >TVA % *
                  <button
                    type="button"
                    v-b-modal.addtvaModal
                    class="ml-2 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    /></button
                ></label>
                <b-form-select
                  id="tva"
                  v-model="configurationToUpdate.tva"
                  required
                  :options="getAllTvas"
                  class="b-form-select-raduis"
                  @change="calculTTC"
                  text-field="valeur"
                  value-field="valeur"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="TVA %"
                ></b-form-select>
              </div>
              <b-form-group
                label="Montant TTC"
                label-for="PU HT"
                class="input-modal-champ col-2 p-0"
              >
                <b-form-input
                  :disabled="true"
                  id="montant"
                  v-model="montant_ttc"
                  required
                  type="number"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Date début"
                label-for="Début de configuration"
                class="input-modal-champ"
              >
                <date-picker
                  value-type="format"
                  v-model="configurationToUpdate.date_debut"
                  type="date"
                  required
                  :clear-button="true"
                  @change="handleErrorUpdateConfig"
                  :disabled-date="disabledStartDateUpdate"
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Date fin"
                label-for="filiale-name"
                class="input-modal-champ mb-0"
              >
                <date-picker
                  v-model="configurationToUpdate.date_fin"
                  value-type="format"
                  type="date"
                  :clear-button="true"
                  @change="handleErrorUpdateConfig"
                  :disabled-date="disabledEndDateUpdate"
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Fréquence de facturation"
                label-for="fréquence de facturation"
                class="input-modal-champ"
              >
                <b-form-select
                  id="frequance"
                  v-model="configurationToUpdate.frequence"
                  required
                  :options="freqOptions"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Objet"
                label-for="Objet"
                class="input-modal-champ"
              >
                <b-form-textarea
                  id="description"
                  row="3"
                  v-model="configurationToUpdate.objet"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Description"
                label-for="Description"
                class="input-modal-champ"
              >
                <b-form-textarea
                  id="description"
                  row="3"
                  v-model="configurationToUpdate.description"
                ></b-form-textarea>
              </b-form-group>
            </div>
            >
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul
                    v-if="Array.isArray(error)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <div class="messageError">
                <div v-if="errorDuplicate" class="errorConfig">
                  <ul v-if="Array.isArray(errorDuplicate)">
                    <li v-for="(e, index) in errorDuplicate" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ errorDuplicate }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style"
                :disabled="validateToAddconf"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      id="duplicateConf"
      ref="duplicateConf"
      title="Modifier configuration"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-modal-size"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Dupliquer une configuration</div>
        <div class="iconClose" @click.prevent="hideModal('duplicateConf')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleDuplicateConfigComptaRh"
            class="form-modal-custom-style"
          >
            <div class="ModelCol">
              <b-form-group
                label="Type"
                label-for="Nom de produit"
                class="input-modal-champ col-4 p-0"
              >
                <b-form-select
                  id="vendeur"
                  v-model="configurationToUpdate.type"
                  required
                  @change="handleErrorDuplicateConfig"
                  :options="ListType"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Liste des configurations"
                label-for="Nom de produit"
                class="input-modal-champ col-4 p-0"
              >
                <b-form-select
                  id="client"
                  v-model="configurationToUpdate.service_bien"
                  required
                  :options="computedListConfig"
                  @change="handleErrorDuplicateConfig"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Jour de création"
                label-for="Nom de produit"
                class="input-modal-champ col-4 p-0"
              >
                <b-form-select
                  id="client"
                  v-model="configurationToUpdate.jour_creation"
                  :options="computedListJourCreation"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Société"
                label-for="Nom de produit"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-select
                  id="vendeur"
                  v-model="configurationToUpdate.vendeur_id"
                  required
                  :options="getSettingFilialesTh"
                  @change="handleErrorDuplicateConfig"
                  text-field="name"
                  value-field="id"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Client"
                label-for="Nom de produit"
                class="input-modal-champ col-3 p-0"
              >
                <b-form-select
                  id="client"
                  v-model="configurationToUpdate.acheteur_id"
                  required
                  :options="getSettingFilialesTh"
                  @change="handleErrorDuplicateConfig"
                  text-field="name"
                  value-field="id"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Montant Hors Taxes"
                label-for="PU HT"
                class="input-modal-champ col-2 p-0"
              >
                <b-form-input
                  id="montant"
                  v-model="configurationToUpdate.montant"
                  required
                  min="0"
                  step="0.00001"
                  @input="calculTTC"
                  type="number"
                ></b-form-input>
              </b-form-group>

              <div role="group" class="form-group col-2 input-modal-champ">
                <label for="swift-template-input" class="d-block"
                  >TVA % *
                  <button
                    type="button"
                    v-b-modal.addtvaModal
                    class="ml-2 btn-add-tva-unite"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    /></button
                ></label>
                <b-form-select
                  id="tva"
                  v-model="configurationToUpdate.tva"
                  required
                  :options="getAllTvas"
                  @change="calculTTC"
                  class="b-form-select-raduis"
                  text-field="valeur"
                  value-field="valeur"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="TVA %"
                ></b-form-select>
              </div>
              <b-form-group
                label="Montant TTC"
                label-for="PU HT"
                class="input-modal-champ col-2 p-0"
              >
                <b-form-input
                  :disabled="true"
                  id="montant"
                  v-model="montant_ttc"
                  required
                  type="number"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Date début"
                label-for="Début de configuration"
                class="input-modal-champ"
              >
                <date-picker
                  value-type="format"
                  v-model="configurationToUpdate.date_debut"
                  type="date"
                  required
                  :clear-button="true"
                  :disabled-date="disabledStartDateUpdate"
                  @change="handleErrorDuplicateConfig"
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Date fin"
                label-for="filiale-name"
                class="input-modal-champ mb-0"
              >
                <date-picker
                  v-model="configurationToUpdate.date_fin"
                  value-type="format"
                  type="date"
                  :clear-button="true"
                  :disabled-date="disabledEndDateUpdate"
                  @change="handleErrorDuplicateConfig"
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Fréquence de facturation"
                label-for="fréquence de facturation"
                class="input-modal-champ"
              >
                <b-form-select
                  id="frequance"
                  v-model="configurationToUpdate.frequence"
                  required
                  :options="freqOptions"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Objet"
                label-for="Objet"
                class="input-modal-champ"
              >
                <b-form-textarea
                  id="description"
                  row="3"
                  v-model="configurationToUpdate.objet"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="ModelCol">
              <b-form-group
                label="Description"
                label-for="Description"
                class="input-modal-champ"
              >
                <b-form-textarea
                  id="description"
                  row="3"
                  v-model="configurationToUpdate.description"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul
                    v-if="Array.isArray(error)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <div class="messageError">
                <div v-if="errorDuplicate" class="errorConfig">
                  <ul v-if="Array.isArray(errorDuplicate)">
                    <li v-for="(e, index) in errorDuplicate" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ errorDuplicate }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style"
                :disabled="validateToAddconf"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="deleteConfigurationModal"
      id="deleteConfigurationModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une configuration</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteConfigurationModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleDeleteConfComptaRh"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>Êtes-vous sur de vouloir supprimer cette configuration?</p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Gestion-des-produits',
  data() {
    return {
      errorDuplicate: null,
      validateToAddconf: false,
      message: null,
      loading: false,
      error: [],
      confOrigin: null,
      isSelectInput: false,
      listTva: [],
      valeur: null,
      errorTvaUnite: null,
      configurationToDelete: null,
      configurationToUpdate: null,
      freqOptions: [{ text: 'Mensuel', value: 'mensuel' }],
      oldConfigurationToUpdate: null,
      configurationToAdd: {
        type: null,
        service_bien: null,
        vendeur_id: null,
        acheteur_id: null,
        montant: null,
        tva: null,
        description: null,
        objet: null,
        date_debut: null,
        date_fin: null,
        frequence: 'mensuel',
      },
      fields: [
        {
          key: 'details',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true,
        },
        {
          key: 'nom_service_bien',
          label: 'Nom Service',
          isSortable: true,
          thClass: 'th-col-client',
          tdClass: 'td-col-client',
        },

        {
          key: 'name',
          label: 'Societé',
          isSortable: true,
        },
        {
          key: 'vendeur',
          label: 'Client',
          isSortable: true,
        },

        {
          key: 'reference',
          label: 'Référence',
          isSortable: true,
        },
        {
          key: 'montant',
          label: 'Montant',
          isSortable: true,
        },
        {
          key: 'tva',
          label: 'TVA',
          isSortable: true,
        },
        {
          key: 'date_debut',
          label: 'Date début',
          isSortable: true,
        },
        {
          key: 'date_fin',
          label: 'Date fin',
          isSortable: true,
        },
        {
          key: 'jour_creation',
          label: 'Jour création',
          isSortable: true,
        },
        {
          key: 'frequence',
          label: 'Fréquence',
          isSortable: true,
        },

        {
          key: 'description',
          label: 'Description',
          isSortable: true,
        },
        {
          key: 'objet',
          label: 'Objet',
          isSortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          isSortable: true,
          thClass: 'th-col-actions',
          tdClass: 'td-col-actions',
        },
      ],
      loadingTvaUnite: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      disable: true,
    };
  },
  filters: {
    formateDate: (value) => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    },
    formateJourCreation: (value) => {
      if (value == '' || value == null) {
        return 'Date du jour';
      } else if (value == -1) {
        return 'Fin du mois';
      } else {
        return value;
      }
    },
  },
  methods: {
    ...mapActions([
      'getServices',
      'getAllBiens',
      'addNewconfComptaRh',
      'fetchAllComptaRh',
      'getSettingFilialeTh',
      'deleteConfComptaRh',
      'updateConfigurationComptaRh',
      'checkPeriodConfig',
      'removeVendeur',
      'removeAcheteur',
      'removePeriod',
    ]),
    async handleErrorUpdateConfig() {
      var objToSend = {
        vendeur_id: this.configurationToUpdate.vendeur_id
          ? this.configurationToUpdate.vendeur_id
          : null,
        acheteur_id: this.configurationToUpdate.acheteur_id
          ? this.configurationToUpdate.acheteur_id
          : null,
        date_debut: this.configurationToUpdate.date_debut
          ? this.configurationToUpdate.date_debut
          : null,
        date_fin: this.configurationToUpdate.date_fin
          ? this.configurationToUpdate.date_fin
          : null,
        id: this.configurationToUpdate.id,
        type: this.configurationToUpdate.type,
        service_bien: this.configurationToUpdate.service_bien,
      };

      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.errorDuplicate = null;
          }
        } else {
          this.errorDuplicate = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    calculTTC() {
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
    },
    async handleDuplicateConfigComptaRh() {
      this.loading = true;
      const response = await this.addNewconfComptaRh({
        configurationToAdd: this.configurationToUpdate,
        type: 'service',
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('duplicateConf');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    updateCurrentConf(data) {
      this.configurationToUpdate = { ...data };
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
      this.oldConfigurationToUpdate = data;
      this.$refs['updateConfComptaRh'].show();
    },
    duplicateConf(data) {
      this.configurationToUpdate = { ...data };
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
      this.$refs['duplicateConf'].show();
      this.handleErrorDuplicateConfig();
    },
    async handleUpdateConfigComptaRh() {
      this.loading = true;
      const response = await this.updateConfigurationComptaRh({
        configurationToUpdate: this.configurationToUpdate,
        oldConfigurationToUpdate: this.oldConfigurationToUpdate,
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('updateConfComptaRh');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    deleteCurrentConf(data) {
      this.confToDelete = data;
      this.$refs['deleteConfigurationModal'].show();
    },
    async handleDeleteConfComptaRh() {
      this.loading = true;
      const response = await this.deleteConfComptaRh({
        configToDelete: this.confToDelete,
        type: 'service',
      });
      if (response.success) {
        this.loading = false;

        this.hideModal('deleteConfigurationModal');
        if (this.getAllConfMensuel.length === 0 && this.page > 1) {
          let newPage = this.page - 1;
          this.pagination(newPage);
        }
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.configurationToAdd.date_debut)
      );
    },
    disabledEndDateUpdate(date) {
      return (
        this.configurationToUpdate?.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.configurationToUpdate?.date_debut)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.configurationToUpdate?.date_fin &&
        moment(date, 'YYYY-MM-DD') >=
          new Date(this.configurationToUpdate?.date_fin)
      );
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.configurationToAdd.date_fin)
      );
    },
    async changePerPage() {
      this.page = 1;
      await this.fetchAllComptaRh({
        per_page: this.perPage,
        page: this.page,
        grouped: 'service',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.fetchAllComptaRh({
        per_page: this.perPage,
        page: this.page,
        grouped: 'service',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleErrorDuplicateConfig() {
      var objToSend = {
        vendeur_id: this.configurationToUpdate.vendeur_id
          ? this.configurationToUpdate.vendeur_id
          : null,
        acheteur_id: this.configurationToUpdate.acheteur_id
          ? this.configurationToUpdate.acheteur_id
          : null,
        date_debut: this.configurationToUpdate.date_debut
          ? this.configurationToUpdate.date_debut
          : null,
        date_fin: this.configurationToUpdate.date_fin
          ? this.configurationToUpdate.date_fin
          : null,
        type: this.configurationToUpdate.type,
        service_bien: this.configurationToUpdate.service_bien,
      };
      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.errorDuplicate = null;
          }
        } else {
          this.errorDuplicate = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    resetModal() {
      this.loading = false;
      this.error = [];
      this.errorDuplicate = null;
      this.validateToAddconf = false;
    },
  },
  components: {
    Card: () => import('@/views/component/card.vue'),
  },
  computed: {
    ...mapGetters([
      'getAllTvas',
      'getSettingFilialesTh',
      'getConfErrors',
      'getAllConfMensuel',
      'getTotalRowConfComptaRh',
      'checkPermission',
      'biens',
      'services',
      'getFiltre',
    ]),
    computedListJourCreation() {
      return [
        { text: 'Jour du création', value: null },
        { text: 'Fin du mois', value: -1 },
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '21', value: 21 },
        { text: '22', value: 22 },
        { text: '23', value: 23 },
        { text: '24', value: 24 },
        { text: '25', value: 25 },
        { text: '26', value: 26 },
        { text: '27', value: 27 },
        { text: '28', value: 28 },
        { text: '29', value: 29 },
        { text: '30', value: 30 },
        { text: '31', value: 31 },
      ];
    },
    ListType() {
      let list = [];
      if (this.checkPermission('GBHT')) {
        list.push({ value: 'bien', text: 'Bien' });
      }
      if (this.checkPermission('GCM')) {
        list.push({ value: 'service', text: 'Service' });
      }
      return list;
    },
    computedListConfig() {
      let selected = [];
      if (
        this.configurationToAdd?.type == 'service' ||
        this.configurationToUpdate?.type == 'service'
      ) {
        return this.services.map((service) => {
          return {
            value: service.id,
            text: service.nom,
          };
        });
      } else if (
        this.configurationToAdd?.type == 'bien' ||
        this.configurationToUpdate?.type == 'bien'
      ) {
        return this.biens.map((bien) => {
          return {
            value: bien.id,
            text: bien.nom,
          };
        });
      } else {
        return selected;
      }
    },
  },
  watch: {
    'getFiltre.vendeur': function () {
      this.fetchAllComptaRh({
        per_page: this.perPage,
        page: this.page,
        grouped: 'service',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
      });
    },
    'getFiltre.acheteur': function () {
      this.fetchAllComptaRh({
        per_page: this.perPage,
        page: this.page,
        grouped: 'service',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
      });
    },
    'getFiltre.period': function () {
      this.fetchAllComptaRh({
        per_page: this.perPage,
        page: this.page,
        grouped: 'service',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
      });
    },
  },
  async mounted() {
    await this.fetchAllComptaRh({
      per_page: this.perPage,
      page: this.page,
      grouped: 'service',
      vendeur_ids: this.getFiltre.vendeur,
      acheteur_ids: this.getFiltre.acheteur,
      period: this.getFiltre.period,
    });
  },
};
</script>

<style scoped lang="scss">

.gestion-config-service {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .table-configuration-mensuel-style {
    max-height: calc(100vh - 283px) !important;
    height: calc(100vh - 283px) !important;
    margin-bottom: 0px;
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .body-box-setting {
    height: calc(100vh - 204px) !important;
  }
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
.tableFixHead {
  position: sticky;
  top: -10px;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 242px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}

.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
</style>
<style lang="scss">
.table-rapport-style .table-configuration-mensuel-style {
  .button-warning-style {
    background-color: #c33025;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .button-update-style {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .button-duplicate-style {
    background-color: #17a2b8;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .th-col-client {
    z-index: 10 !important;
    position: sticky !important;
    left: 32px;
  }

  .td-col-client {
    z-index: 5 !important;
    position: sticky !important;
    left: 32px;
  }
  .th-col-actions {
    z-index: 10 !important;
    position: sticky !important;
    right: -10px;
  }

  .td-col-actions {
    z-index: 5 !important;
    position: sticky !important;
    right: -10px;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .button-succes-style {
      // background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .td-col-actions,
    .td-col-client,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd985;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #cdcce2 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;
      color: black;
      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
</style>
