<template>
  <div class="setting-config-frais-familly">
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table-simple class="table-fiche custom-table-style mb-0" responsive>
          <b-tbody>
            <b-tr class="title-ligne">
              <b-th colspan="1" class="newcolor">Societé</b-th>
              <b-th colspan="1" class="newcolor actions"></b-th>
            </b-tr>
            <template v-for="(item, index) in getListFamilleConfigFrias">
              <b-tr :key="'conf' + index + item.id">
                <b-td colspan="1">{{ item.familly }}</b-td>
                <b-td colspan="1" class="actions">
                  <b-button
                    v-if="checkPermission('GDFCMUF')"
                    class="button-succes-style ml-2"
                    size="sm"
                    variant="succes"
                    @click="updateFamillyAction(item)"
                  >
                    <font-awesome-icon icon="pencil-alt" />
                  </b-button>
                  <b-button
                    v-if="checkPermission('GDFCVUF')"
                    class=" ml-2 button-clocked-style "
                    size="sm"
                    variant="succes"
                    @click="BlockUnblockConfigclick(item)"
                  >
                    <font-awesome-icon
                      v-if="item.blocked == true"
                      title="Déblocker configuration"
                      icon="eye-slash"
                      class="fa-2x bell1 "
                    />

                    <font-awesome-icon
                      v-if="item.blocked == false"
                      title="blocker configuration"
                      icon="eye"
                      class="fa-2x bell1 "
                    />
                  </b-button>
                </b-td>
              </b-tr>
            </template>
            <b-tr v-if="getListFamilleConfigFrias.length == 0">
              <b-td colspan="2"> Il n'y a aucun enregistrement à afficher</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <b-modal
      id="updateConfigurationFamilleFraisModal"
      ref="updateConfigurationFamilleFraisModal"
      title="Modifier une famille"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier une famille</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateConfigurationFamilleFraisModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="hendleSubmitFamille"
            class="form-modal-custom-style"
            v-if="famillyToUpdate != null"
          >
            <b-form-group
              label="Nom"
              label-for="nom"
              class="input-modal-champ "
            >
              <b-form-input
                id="nom"
                v-model="famillyToUpdate.familly"
                required
              ></b-form-input>
            </b-form-group>

            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="getErrorFamilleConfig" class="error">
                  <ul v-if="Array.isArray(getErrorFamilleConfig)">
                    <li
                      v-for="(e, index) in getErrorFamilleConfig"
                      :key="index"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ getErrorFamilleConfig }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style ">
                <span>
                  Valider
                  <div v-if="getLoadingFamilleConfig" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return { famillyToUpdate: null, familly: null };
  },
  methods: {
    ...mapActions([
      'fetchAllFammilleConfig',
      'BlockUnblockConfigFamilleFraisDync',
      'initErrorsFamillyConfig',
      'updateConfigFamilleFraisDync'
    ]),

    resetModal() {
      this.famillyToUpdate = null;
      this.familly = null;
      this.initErrorsFamillyConfig();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async updateFamillyAction(data) {
      this.familly = data;
      this.famillyToUpdate = { ...data };
      this.$refs['updateConfigurationFamilleFraisModal'].show();
    },
    async BlockUnblockConfigclick(item) {
      await this.BlockUnblockConfigFamilleFraisDync(item);
    },
    async hendleSubmitFamille() {
      const response = await this.updateConfigFamilleFraisDync({
        familly: this.familly,
        famillyToUpdate: this.famillyToUpdate
      });
      if (response.success) {
        this.hideModal('updateConfigurationFamilleFraisModal');
      }
    },
    fetchAllFammilleConfigAction() {
      this.fetchAllFammilleConfig({});
    }
  },

  computed: {
    ...mapGetters([
      'getListFamilleConfigFrias',
      'getErrorFamilleConfig',
      'getLoadingFamilleConfig',
      'getErrorFamilleConfig',
      'checkPermission'
    ])
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  },

  mounted() {
    this.fetchAllFammilleConfigAction();
  }
};
</script>

<style scoped lang="scss">
.setting-config-frais-familly {
  width: 100%;
  .body-box-setting {
    height: calc(100vh - 180px);
  }
  .table-rapport-style {
    height: calc(100vh - 200px);
  }
  .button-succes-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
    border-radius: 5px;
  }
  .button-clocked-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
  }
  .table-fiche {
    width: 100%;
    height: calc(100vh - 214px);
  }
  .newcolor {
    min-width: 100px;
  }
  .actions {
    position: sticky;
    right: -1px !important;
  }
}
</style>
<style lang="scss">
.table-fiche::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.table-fiche {
  table {
    display: table !important;
  }
}

.table-fiche::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}

.table-fiche::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}

.cutsom-modal-bootstrap-filiale-sci .modal-dialog {
  top: 0%;
  max-width: 70%;
}
.scroll-specifics-params {
  height: calc(100vh - 266px);
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
</style>
