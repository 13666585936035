<template>
  <div class="visibility-permission">
    <div class="permission-hearder">
      <div class="title">Utilisateurs</div>
      <div class="Search-style-component" v-if="checkPermission('GPHTCU')">
        <div class="search-box">
          <font-awesome-icon icon="search" class="icon" />
          <input
            class="search-input"
            placeholder="Chercher"
            v-model="search"
            @input="debounceInput"
          />
        </div>
      </div>

      <div v-if="loadingAdd" class="chargement-message-text ml-2">
        Chargement en cours
      </div>
      <div class="fix-height-slide-user">
        <div
          v-for="user in Allusers"
          :key="user.id"
          @click.prevent="handleClickUser(user)"
          class="selected-role"
        >
          <div id="info-wrap" class="interface-permission">
            <div class="description-column">
              <span>
                {{ user.full_name }}
              </span>
            </div>
            <div class="info-column">
              <div
                class="icon-color"
                v-if="selectedUser && selectedUser.full_name == user.full_name"
              >
                <font-awesome-icon icon="check" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="permission-hearder" v-if="selectedUser">
      <div class="permission-user" v-if="selectedUser">
        <div class="title">Interfaces</div>

        <div class="list-interface fix-height-slide">
          <div
            v-for="permission in getListePermission"
            :key="permission.key"
            class="selected-role"
          >
            <div id="info-wrap" class="interface-permission">
              <div>
                <div class="info-column">
                  <b-form-checkbox
                    name="flavour-1"
                    :id="'checkbox-group-1' + permission.key"
                    class="check-th"
                    v-model="permission.checked"
                    @change="assignPermissionInterface(permission)"
                    v-if="checkPermission('GPHTAPUS')"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="description-column">
                <span>
                  {{ permission.name }}
                </span>
              </div>
              <div class="info-column">
                <div
                  class="icon-color"
                  v-if="
                    permission &&
                    ((permission.filters && permission.filters.length != 0) ||
                      (permission.fonctionalite &&
                        permission.fonctionalite.length != 0) ||
                      (permission.colonnes &&
                        permission.colonnes.length != 0) ||
                      (permission.interfaces &&
                        permission.interfaces.length != 0))
                  "
                >
                  <img
                    src="@/assets/Icon ionic-md-arrow-dropright-circle.png"
                    class="flesh-icon-left"
                    :class="{
                      'flesh-icon-bottom':
                        selectedPage && permission.key == selectedPage.key,
                    }"
                    @click="handleClickInterface(permission)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="permission-hearder-autorisations"
      v-if="
        selectedPage &&
        selectedPage &&
        (selectedPage.filters.length != 0 ||
          selectedPage.colonnes.length != 0 ||
          selectedPage.fonctionalite.length != 0)
      "
    >
      <div class="permission-user" v-if="selectedPage">
        <div class="title">
          Autorisations
          <div v-if="loading" class="chargement-message-calender">
            Chargement en cours...
          </div>
        </div>
        <div class="list-interface fix-height-slide">
          <div class="autorisation-permission">
            <div class="accordion" role="tablist">
              <b-card
                no-body
                class="mb-1"
                v-if="
                  selectedPage &&
                  selectedPage.filters &&
                  selectedPage.filters.length
                "
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1 variant="info"
                    >Filters</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <div class="row ml-5">
                        <div class="col-1">
                          <b-form-checkbox
                            name="flavour-1"
                            id="checkbox-group-filter"
                            class="check-th"
                            @change="
                              checkAllPermissions($event, selectedPage.filters)
                            "
                            :checked="computedChecked(selectedPage.filters)"
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width-auto">Tous</div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <b-card-body
                    v-for="filter in selectedPage.filters"
                    :key="filter.key"
                  >
                    <b-card-text>
                      <div class="row ml-5">
                        <div class="col-1">
                          <b-form-checkbox
                            name="flavour-1"
                            :id="'checkbox-group-1Tous' + filter.key"
                            class="check-th"
                            v-model="filter.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width-auto">{{ filter.name }}</div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <div class="row ml-5">
                    <div class="col-1"></div>
                    <div class="col-9">
                      <b-button
                        @click.prevent.stop="
                          assignPermission(selectedPage.filters, 'filter')
                        "
                        type="submit"
                        class="button-valide-style mt-1 ml-3"
                      >
                        <span>
                          Enregistrer
                          <div v-if="loadingFilter" class="loading ml-2">
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>

              <b-card
                no-body
                class="mb-1"
                v-if="
                  selectedPage &&
                  selectedPage.colonnes &&
                  selectedPage.colonnes.length
                "
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 variant="info"
                    >Colonnes</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <div class="row ml-5">
                        <div class="col-1">
                          <b-form-checkbox
                            name="flavour-1"
                            id="checkbox-group-colonne"
                            class="check-th"
                            :checked="computedChecked(selectedPage.colonnes)"
                            :unchecked-value="true"
                            @change="
                              checkAllPermissions($event, selectedPage.colonnes)
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width-auto">Tous</div>
                      </div>
                    </b-card-text>
                  </b-card-body>

                  <b-card-body
                    v-for="colonne in selectedPage.colonnes"
                    :key="colonne.key"
                  >
                    <b-card-text>
                      <div class="row ml-5">
                        <div class="col-1">
                          <b-form-checkbox
                            name="flavour-1"
                            :id="'checkbox-group-1Tous' + colonne.key"
                            class="check-th"
                            v-model="colonne.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width-auto">{{ colonne.name }}</div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <div class="row ml-5">
                    <div class="col-1"></div>
                    <div class="col-9">
                      <b-button
                        type="submit"
                        class="button-valide-style mt-1 ml-3"
                        @click.prevent.stop="
                          assignPermission(selectedPage.colonnes, 'colonne')
                        "
                      >
                        <span>
                          Enregistrer
                          <div v-if="loadingColonne" class="loading ml-2">
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>

              <b-card
                no-body
                class="mb-1"
                v-if="
                  selectedPage &&
                  selectedPage.fonctionalite &&
                  selectedPage.fonctionalite.length
                "
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-3 variant="info"
                    >Fonctionnalités</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="row ml-5">
                      <div class="col-1">
                        <b-form-checkbox
                          :checked="computedChecked(selectedPage.fonctionalite)"
                          name="flavour-1"
                          id="checkbox-group-fonction"
                          @change="
                            checkAllPermissions(
                              $event,
                              selectedPage.fonctionalite
                            )
                          "
                          class="check-th"
                          v-if="checkPermission('GPHTAPUS')"
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="custom-width-auto">Tous</div>
                    </div>
                  </b-card-body>
                  <b-card-body
                    v-for="fonction in selectedPage.fonctionalite"
                    :key="fonction.key"
                  >
                    <div class="row ml-5">
                      <div class="col-1">
                        <b-form-checkbox
                          name="flavour-1"
                          :id="'checkbox-group' + fonction.key"
                          class="check-th"
                          v-model="fonction.checked"
                          v-if="checkPermission('GPHTAPUS')"
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="custom-width-auto">{{ fonction.name }}</div>
                    </div>
                  </b-card-body>
                  <div class="row ml-5">
                    <div class="col-1"></div>
                    <div class="col-9">
                      <b-button
                        type="submit"
                        class="button-valide-style mt-1 ml-3 mb-2"
                        @click.prevent.stop="
                          assignPermission(
                            selectedPage.fonctionalite,
                            'fonction'
                          )
                        "
                      >
                        <span>
                          Enregistrer
                          <div v-if="loadingFonction" class="loading ml-2">
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="permission-hearder"
      v-if="selectedPage && selectedPage.interfaces.length != 0"
    >
      <div class="permission-user" v-if="selectedPage">
        <div class="title">Sous Interfaces</div>
        <div class="fix-height-slide">
          <div
            v-for="inter in selectedPage.interfaces"
            :key="inter.key"
            class="selected-role"
          >
            <div id="info-wrap" class="interface-permission">
              <div>
                <div class="info-column">
                  <b-form-checkbox
                    name="flavour-1"
                    :id="'checkbox-group-1' + inter.key"
                    class="check-th"
                    v-model="inter.checked"
                    @change="assignPermissionInterface(inter)"
                    v-if="checkPermission('GPHTAPUS')"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="description-column">
                <span>
                  {{ inter.name }}
                </span>
              </div>
              <div class="info-column">
                <div class="icon-color">
                  <img
                    v-if="
                      inter &&
                      ((inter.filters && inter.filters.length != 0) ||
                        (inter.colonnes && inter.colonnes.length != 0) ||
                        (inter.fonctionalite &&
                          inter.fonctionalite.length != 0))
                    "
                    src="@/assets/Icon ionic-md-arrow-dropright-circle.png"
                    class="flesh-icon-left"
                    :class="{
                      'flesh-icon-bottom':
                        selectedSousInterface &&
                        selectedSousInterface.key == inter.key,
                    }"
                    @click="handleClickSousInterface(inter)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="permission-hearder"
      v-if="
        selectedSousInterface &&
        (selectedSousInterface.filters.length != 0 ||
          selectedSousInterface.colonnes.length != 0 ||
          selectedSousInterface.fonctionalite.length != 0)
      "
    >
      <div class="permission-user" v-if="selectedPage">
        <div class="title">
          Autorisations
          <div v-if="loading" class="chargement-message-calender">
            Chargement en cours...
          </div>
        </div>
        <div class="list-interface fix-height-slide">
          <div class="autorisation-permission">
            <div class="accordion" role="tablist">
              <b-card
                no-body
                class="mb-1"
                v-if="
                  selectedSousInterface &&
                  selectedSousInterface.filters &&
                  selectedSousInterface.filters.length
                "
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1 variant="info"
                    >Filters</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-1">
                          <b-form-checkbox
                            name="flavour-1"
                            id="checkbox-group-filter"
                            class="check-th"
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedSousInterface.filters
                              )
                            "
                            :checked="
                              computedChecked(selectedSousInterface.filters)
                            "
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width">Tous</div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <b-card-body
                    v-for="filter in selectedSousInterface.filters"
                    :key="filter.key"
                  >
                    <b-card-text>
                      <div class="row">
                        <div class="col-1">
                          <b-form-checkbox
                            name="flavour-1"
                            :id="'checkbox-group-1Tous' + filter.key"
                            class="check-th"
                            v-model="filter.checked"
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width">
                          {{ filter.name }}
                        </div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-9">
                      <b-button
                        @click.prevent.stop="
                          assignPermission(
                            selectedSousInterface.filters,
                            'filter'
                          )
                        "
                        type="submit"
                        class="button-valide-style mt-1 ml-5"
                      >
                        <span>
                          Enregistrer
                          <div v-if="loadingFilter" class="loading ml-">
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>

              <b-card
                no-body
                class="mb-1"
                v-if="
                  selectedSousInterface &&
                  selectedSousInterface.colonnes &&
                  selectedSousInterface.colonnes.length
                "
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 variant="info"
                    >Colonnes</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-1">
                          <b-form-checkbox
                            :checked="
                              computedChecked(selectedSousInterface.colonnes)
                            "
                            name="flavour-1"
                            id="checkbox-group-colonne"
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedSousInterface.colonnes
                              )
                            "
                            class="check-th"
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width">Tous</div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <b-card-body
                    v-for="colonne in selectedSousInterface.colonnes"
                    :key="colonne.key"
                  >
                    <div class="row">
                      <div class="col-1">
                        <b-form-checkbox
                          name="flavour-1"
                          :id="'checkbox-group' + colonne.key"
                          class="check-th"
                          v-model="colonne.checked"
                          v-if="checkPermission('GPHTAPUS')"
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="custom-width">
                        {{ colonne.name }}
                      </div>
                    </div>
                  </b-card-body>
                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-9">
                      <b-button
                        type="submit"
                        class="button-valide-style mt-1 ml-5 mb-2"
                        @click.prevent.stop="
                          assignPermission(
                            selectedSousInterface.colonnes,
                            'colonne'
                          )
                        "
                      >
                        <span>
                          Enregistrer
                          <div v-if="loadingColonne" class="loading ml-2">
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
              <b-card
                no-body
                class="mb-1"
                v-if="
                  selectedSousInterface &&
                  selectedSousInterface.fonctionalite &&
                  selectedSousInterface.fonctionalite.length
                "
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-3 variant="info"
                    >Fonctionnalités</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-1">
                          <b-form-checkbox
                            :checked="
                              computedChecked(
                                selectedSousInterface.fonctionalite
                              )
                            "
                            name="flavour-1"
                            id="checkbox-group-fonction"
                            @change="
                              checkAllPermissions(
                                $event,
                                selectedSousInterface.fonctionalite
                              )
                            "
                            class="check-th"
                            v-if="checkPermission('GPHTAPUS')"
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="custom-width">Tous</div>
                      </div></b-card-text
                    >
                  </b-card-body>
                  <b-card-body
                    v-for="fonction in selectedSousInterface.fonctionalite"
                    :key="fonction.key"
                  >
                    <div class="row">
                      <div class="col-1">
                        <b-form-checkbox
                          name="flavour-1"
                          :id="'checkbox-group' + fonction.key"
                          class="check-th"
                          v-model="fonction.checked"
                          v-if="checkPermission('GPHTAPUS')"
                        >
                        </b-form-checkbox>
                      </div>
                      <div class="custom-width">
                        {{ fonction.name }}
                      </div>
                    </div>
                  </b-card-body>
                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-9">
                      <b-button
                        type="submit"
                        class="button-valide-style mt-1 ml-5 mb-2"
                        @click.prevent.stop="
                          assignPermission(
                            selectedSousInterface.fonctionalite,
                            'fonction'
                          )
                        "
                      >
                        <span>
                          Enregistrer
                          <div v-if="loadingFonction" class="loading ml-2">
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </span>
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  data() {
    return {
      loadingFilter: false,
      loadingColonne: false,
      loadingFonction: false,
      selectedUser: null,
      selectedPage: null,
      selectedSousInterface: null,
      interfaceAutorisation: [],
      sousInterfaceAutorisation: [],
      getListePermission: [],
      search: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions([
      'getAllUsers',
      'assignAutorisationToUser',
      'removeAutorisationToUser',
    ]),
    checkAllPermissions(e, permissions) {
      permissions.map((element) => {
        element.checked = e;
      });
    },
    debounceInput: _.debounce(function (e) {
      this.search = e.target.value;
      let payload = {
        search: this.search,
      };
      this.getAllUsers(payload);
    }, 500),
    async assignPermissionInterface(permission) {
      if (permission.checked == true) {
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        bodyFormData.append('assigned[' + 0 + '][key]', permission.key);
        bodyFormData.append('assigned[' + 0 + '][name]', permission.name);
        let payload = {
          assigned: [permission],
          bodyFormData: bodyFormData,
          user: this.selectedUser.id,
        };
        const response = await this.assignAutorisationToUser(payload);
        if (response.success) {
        }
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        bodyFormData.append('deleted[' + 0 + '][key]', permission.key);
        bodyFormData.append('deleted[' + 0 + '][name]', permission.name);
        let payload = {
          deleted: [permission],
          bodyFormData: bodyFormData,
          user: this.selectedUser.id,
        };
        const response = await this.removeAutorisationToUser(payload);
        if (response.success) {
        }
      }
    },
    async assignPermission(permissions, type) {
      let assigned = [];
      let deleted = [];
      assigned = permissions.filter((item) => item.checked == true);
      deleted = permissions.filter((item) => item.checked == false);
      if (assigned.length != 0) {
        this.loadingFilter = type == 'filter' ? true : false;
        this.loadingColonne = type == 'colonne' ? true : false;
        this.loadingFonction = type == 'fonction' ? true : false;
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        assigned.forEach((element, index) => {
          bodyFormData.append('assigned[' + index + '][key]', element.key);
          bodyFormData.append('assigned[' + index + '][name]', element.name);
        });
        let payload = {
          assigned: assigned,
          bodyFormData: bodyFormData,
          user: this.selectedUser.id,
        };
        const response = await this.assignAutorisationToUser(payload);
        if (response.success) {
          this.loadingFilter = false;
          this.loadingFonction = false;
          this.loadingColonne = false;
        }
      }
      if (deleted.length != 0) {
        this.loadingFilter = type == 'filter' ? true : false;
        this.loadingColonne = type == 'colonne' ? true : false;
        this.loadingFonction = type == 'fonction' ? true : false;
        let bodyFormData = new FormData();
        bodyFormData.append('user', this.selectedUser.id);
        deleted.forEach((element, index) => {
          bodyFormData.append('deleted[' + index + '][key]', element.key);
          bodyFormData.append('deleted[' + index + '][name]', element.name);
        });
        let payload = {
          deleted: deleted,
          bodyFormData: bodyFormData,
          user: this.selectedUser.id,
        };
        const response = await this.removeAutorisationToUser(payload);
        if (response.success) {
          this.loadingFilter = false;
          this.loadingFonction = false;
          this.loadingColonne = false;
        }
      }
    },
    handleClickUser(user) {
      this.getListePermission.map((item) => {
        item.checked = false;
      });
      this.selectedUser = user;
      this.selectedUser.permissions.map((permission) => {
        this.getListePermission.map((item) => {
          if (item.key == permission) {
            item.checked = true;
          }
        });
      });
      this.selectedPage = null;
      this.selectedSousInterface = null;
      this.interfaceAutorisation = [];
      this.sousInterfaceAutorisation = [];
    },
    handleClickInterface(permission) {
      this.selectedPage = permission;
      if (this.selectedPage.colonnes.length != 0) {
        this.selectedPage.colonnes.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedPage.filters.length != 0) {
        this.selectedPage.filters.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedPage.interfaces.length != 0) {
        this.selectedPage.interfaces.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedPage.fonctionalite.length != 0) {
        this.selectedPage.fonctionalite.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedPage.colonnes.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedPage.colonnes.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.filters.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedPage.filters.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.fonctionalite.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedPage.fonctionalite.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedPage.interfaces.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedPage.interfaces.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      this.selectedSousInterface = null;
    },
    handleClickSousInterface(inter) {
      this.selectedSousInterface = inter;
      if (this.selectedSousInterface.colonnes.length != 0) {
        this.selectedSousInterface.colonnes.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.filters.length != 0) {
        this.selectedSousInterface.filters.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.fonctionalite.length != 0) {
        this.selectedSousInterface.fonctionalite.map((item) => {
          item.checked = false;
        });
      }
      if (this.selectedSousInterface.filters.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedSousInterface.filters.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedSousInterface.fonctionalite.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedSousInterface.fonctionalite.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
      if (this.selectedSousInterface.colonnes.length != 0) {
        this.selectedUser.permissions.map((per) => {
          this.selectedSousInterface.colonnes.map((item) => {
            if (item.key == per) {
              item.checked = true;
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters(['Allusers', 'loadingAdd', 'checkPermission']),
    computedChecked() {
      return function (filters) {
        let tableCheck = filters.filter((item) => item.checked == true);
        if (tableCheck.length == filters.length) {
          return true;
        }
        return false;
      };
    },
  },
  mounted() {
    this.getListePermission = permissionModel.create().list;
    this.getAllUsers({ search: this.search });
  },
};
</script>

<style lang="scss" scoped>
.Search-style-component {
  .search-box {
    position: relative;
    top: 0;
    right: 0px;
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .search-input {
      height: 18px;
      width: 100%;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: black;
      font-size: 13px;

      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }
}
.visibility-permission {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5c7;
  padding: 10px;
  border-radius: 15px;

  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .permission-hearder {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 15px;
    width: 25%;
    max-width: 25%;
    margin-right: 10px;
  }
  .permission-hearder-autorisations {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 15px;
    width: 50%;
    max-width: 50%;
    margin-right: 10px;
  }
}
</style>
<style lang="scss">
.visibility-permission {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }
  .description-column {
    width: 72%;
    float: left;
    padding-left: 4px;
    font-size: 11px;
  }
  .info-column {
    width: 10%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
  }
  .autorisation-permission {
    padding: 3px;
    padding-left: 5px;
    margin-bottom: 9px;
    font-size: medium;
  }
  .fix-height-slide {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 270px);
    padding-right: 9px;
  }

  .fix-height-slide::-webkit-scrollbar {
    width: 8px;
  }
  .fix-height-slide::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  .fix-height-slide::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }
  .fix-height-slide::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .fix-height-slide-user {
    display: block;
    overflow-y: scroll !important;
    height: calc(100vh - 325px);
  }

  .fix-height-slide-user::-webkit-scrollbar {
    width: 8px;
  }

  .fix-height-slide-user::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  .fix-height-slide-user::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  .fix-height-slide-user::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
  .icon-color {
    color: #4d4bac;
  }
  .selected-role {
    cursor: pointer;
  }
}
</style>
